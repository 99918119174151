import React, { useEffect, useState, useRef } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
// config file

const Tickets = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);

  let dataTableRef = useRef(null);
  const params = useParams();
  useEffect(() => {
    let screenPermissions = RolePermissions.screenPermissions("Tickets");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
      storeData.settingsData &&
      storeData.settingsData.settings &&
      storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const getTableFields = () => {
   
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "S.No",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        fieldName: "createdByName",
        type: "text",
        placeholder: "Created By",
        value: "",
        label: "Created By",
        width: 120,
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Created By",
        derivedValue: "createdByName=createdByName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "createdByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "left",
        show: true,
        field: "createdByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "officeName",
        fieldName: "officeObjId",
        type: "relateAutoComplete",
        placeholder: "Office Name",
        value: "",
        label: "Office Name",
        width: "150px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Office Name",
        derivedValue: "officeName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "officeName",
        displayinaddForm: "false",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: 1013,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        searchField: "name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        controllerName: "Office",
        searchApi: "offices",
        textAlign: "center",
        show: true,
        field: "officeObjId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "subject",
        fieldName: "subject",
        type: "text",
        placeholder: "Subject",
        value: "",
        label: "Subject",
        width: "200px",
        addFormOrder: 1,
        editFormOrder: 1,
        stringType: null,
        header: "Subject",
        derivedValue: "subject=subject=subject=subject=subject=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "subject",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: true,
        field: "subject",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "description",
        fieldName: "description",
        type: "textarea",
        placeholder: "Description",
        value: "",
        label: "Description",
        width: "200px",
        addFormOrder: 2,
        editFormOrder: 2,
        stringType: null,
        header: "Description",
        derivedValue:
          "description=description=description=description=description=message=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: true,
        field: "description",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "status",
        fieldName: "status",
        type: "dropDown",
        placeholder: "Status",
        value: "",
        label: "Status",
        width: "120px",
        addFormOrder: 3,
        editFormOrder: 3,
        stringType: null,
        header: "Status",
        derivedValue:
          "status=status=status=status=status=status=status=status=status=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "status",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          {
            label: "New",
            value: "new",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Open",
            value: "open",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Pending",
            value: "pending",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "On Hold",
            value: "onHold",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Resolved",
            value: "resolved",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "No Issue",
            value: "noIssue",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
        ],
        fieldType: "dropDown",
        filterElement: [
          {
            label: "New",
            value: "new",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Open",
            value: "open",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Pending",
            value: "pending",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "On Hold",
            value: "onHold",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Resolved",
            value: "resolved",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "No Issue",
            value: "noIssue",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "status",
        showOrHideFields: [],
        label0: "New",
        dependentField0: "",
        color0: "primary",
        value0: "new",
        label1: "Open",
        dependentField1: "",
        color1: "secondary",
        value1: "open",
        label2: "Pending",
        dependentField2: "",
        color2: "warning",
        value2: "pending",
        label3: "On Hold",
        dependentField3: "",
        color3: "danger",
        value3: "onHold",
        label4: "Resolved",
        dependentField4: "",
        color4: "success",
        value4: "resolved",
        label5: "No Issue",
        dependentField5: "",
        color5: "info",
        value5: "noIssue",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "priority",
        fieldName: "priority",
        type: "dropDown",
        placeholder: "Priority",
        value: "",
        label: "Priority",
        width: "120px",
        addFormOrder: 4,
        editFormOrder: 4,
        stringType: null,
        header: "Priority",
        derivedValue: "priority=priority=priority=priority=priority=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "priority",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          {
            label: "Low",
            value: "low",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Normal",
            value: "normal",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Urgent",
            value: "urgent",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
        ],
        fieldType: "dropDown",
        filterElement: [
          {
            label: "Low",
            value: "low",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Normal",
            value: "normal",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Urgent",
            value: "urgent",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "priority",
        showOrHideFields: [],
        label0: "Low",
        dependentField0: "",
        color0: "primary",
        value0: "low",
        label1: "Normal",
        dependentField1: "",
        color1: "info",
        value1: "normal",
        label2: "Urgent",
        dependentField2: "",
        color2: "danger",
        value2: "urgent",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "comments",
        fieldName: "comments",
        type: "textarea",
        placeholder: "Comments",
        value: "",
        label: "Comments",
        width: "120px",
        addFormOrder: 5,
        editFormOrder: 5,
        stringType: null,
        header: "Comments",
        derivedValue:
          "comments=comments=comments=comments=comments=comments=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "comments",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: false,
        field: "comments",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: false,
        tDisplay: true,
      },
      {
        name: "attachments",
        fieldName: "attachments",
        type: "fileUpload",
        placeholder: "Attachments",
        value: "",
        label: "Attachments",
        width: "120px",
        addFormOrder: 6,
        editFormOrder: 6,
        stringType: null,
        header: "Attachments",
        derivedValue: "attachments=attachments=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "attachments",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        fieldType: "fileUpload",
        fileType: "img/video",
        maxFileSize: 3,
        isMultipleRequired: true,
        imagePath: "Tickets",
        textAlign: "center",
        show: true,
        field: "attachments",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        fieldName: "updatedByName",
        type: "text",
        placeholder: "Updated By",
        value: "",
        label: "Updated By",
        width: 120,
        addFormOrder: 9,
        editFormOrder: 9,
        header: "Updated By",
        derivedValue: "updatedByName=updatedByName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "updatedByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "left",
        show: true,
        field: "updatedByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        fieldName: "created",
        type: "date",
        placeholder: "Created",
        value: "",
        label: "Created",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Created",
        derivedValue: "created=created=created=undefined",
        capitalizeTableText: "",
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "created",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        fieldName: "updated",
        type: "date",
        placeholder: "Updated",
        value: "",
        label: "Updated",
        width: 90,
        addFormOrder: 11,
        editFormOrder: 11,
        header: "Updated",
        derivedValue: "updated=updated=updated=undefined",
        capitalizeTableText: "",
        sortable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "updated",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 100,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "true",
        displayineditForm: "true",
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        tDisplay: true,
      },
    ];
    return data;
  };

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "subject",
        fieldName: "subject",
        type: "text",
        placeholder: "Subject",
        value: "",
        label: "Subject",
        width: "200px",
        addFormOrder: 1,
        editFormOrder: 1,
        stringType: null,
        derivedValue: "subject=subject=subject=subject=subject=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "subject",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "description",
        fieldName: "description",
        type: "textarea",
        placeholder: "Description",
        value: "",
        label: "Description",
        width: "200px",
        addFormOrder: 4,
        editFormOrder: 4,
        stringType: null,
        derivedValue:
          "description=description=description=description=description=message=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "status",
        fieldName: "status",
        type: "dropDown",
        placeholder: "Status",
        value: "",
        label: "Status",
        width: "120px",
        addFormOrder: 2,
        editFormOrder: 2,
        stringType: null,
        derivedValue:
          "status=status=status=status=status=status=status=status=status=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "status",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          {
            label: "New",
            value: "new",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Open",
            value: "open",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Pending",
            value: "pending",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "On Hold",
            value: "onHold",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Resolved",
            value: "resolved",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "No Issue",
            value: "noIssue",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
        ],
        fieldType: "dropDown",
        filterElement: [
          {
            label: "New",
            value: "new",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Open",
            value: "open",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Pending",
            value: "pending",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "On Hold",
            value: "onHold",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Resolved",
            value: "resolved",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "No Issue",
            value: "noIssue",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [],
        show: true,
        showOrHideFields: [],
        label0: "New",
        dependentField0: "",
        color0: "primary",
        value0: "new",
        label1: "Open",
        dependentField1: "",
        color1: "secondary",
        value1: "open",
        label2: "Pending",
        dependentField2: "",
        color2: "warning",
        value2: "pending",
        label3: "On Hold",
        dependentField3: "",
        color3: "danger",
        value3: "onHold",
        label4: "Resolved",
        dependentField4: "",
        color4: "success",
        value4: "resolved",
        label5: "No Issue",
        dependentField5: "",
        color5: "info",
        value5: "noIssue",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "priority",
        fieldName: "priority",
        type: "dropDown",
        placeholder: "Priority",
        value: "",
        label: "Priority",
        width: "120px",
        addFormOrder: 3,
        editFormOrder: 3,
        stringType: null,
        derivedValue: "priority=priority=priority=priority=priority=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "priority",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          {
            label: "Low",
            value: "low",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Normal",
            value: "normal",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Urgent",
            value: "urgent",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
        ],
        fieldType: "dropDown",
        filterElement: [
          {
            label: "Low",
            value: "low",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Normal",
            value: "normal",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Urgent",
            value: "urgent",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [],
        show: true,
        showOrHideFields: [],
        label0: "Low",
        dependentField0: "",
        color0: "primary",
        value0: "low",
        label1: "Normal",
        dependentField1: "",
        color1: "info",
        value1: "normal",
        label2: "Urgent",
        dependentField2: "",
        color2: "danger",
        value2: "urgent",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "comments",
        fieldName: "comments",
        type: "textarea",
        placeholder: "Comments",
        value: "",
        label: "Comments",
        width: "120px",
        addFormOrder: 7,
        editFormOrder: 7,
        stringType: null,
        derivedValue:
          "comments=comments=comments=comments=comments=comments=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "comments",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "attachments",
        fieldName: "attachments",
        type: "fileUpload",
        placeholder: "Attachments",
        value: "",
        label: "Attachments",
        width: "120px",
        addFormOrder: 6,
        editFormOrder: 6,
        stringType: null,
        derivedValue: "attachments=attachments=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "attachments",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        fieldType: "fileUpload",
        fileType: "img/video",
        maxFileSize: 10,
        acceptType: "image/*, video/*",
        isMultipleRequired: true,
        imagePath: "Tickets",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "createdByName",
        fieldName: "createdByName",
        type: "text",
        placeholder: "Created By",
        value: "",
        label: "Created By",
        width: 120,
        addFormOrder: 8,
        editFormOrder: 8,
        derivedValue: "createdByName=createdByName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "createdByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "updatedByName",
        fieldName: "updatedByName",
        type: "text",
        placeholder: "Updated By",
        value: "",
        label: "Updated By",
        width: 120,
        addFormOrder: 9,
        editFormOrder: 9,
        derivedValue: "updatedByName=updatedByName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "updatedByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "created",
        fieldName: "created",
        type: "date",
        placeholder: "Created",
        value: "",
        label: "Created",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        derivedValue: "created=created=created=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "updated",
        fieldName: "updated",
        type: "date",
        placeholder: "Updated",
        value: "",
        label: "Updated",
        width: 90,
        addFormOrder: 11,
        editFormOrder: 11,
        derivedValue: "updated=updated=updated=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "officeName",
        fieldName: "officeName",
        type: "relateAutoComplete",
        placeholder: "Office Name",
        value: "",
        label: "Office Name",
        width: "150px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        derivedValue: "officeName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "officeName",
        displayinaddForm: "false",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: 1013,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        searchField: "name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        controllerName: "Office",
        searchApi: "offices",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
    console.log("Submit Button in sode          ");
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "subject",
        fieldName: "subject",
        type: "text",
        placeholder: "Subject",
        value: "",
        label: "Subject",
        width: "200px",
        addFormOrder: 1,
        editFormOrder: 1,
        stringType: null,
        header: "Subject",
        derivedValue: "subject=subject=subject=subject=subject=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "subject",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "subject",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "description",
        fieldName: "description",
        type: "textarea",
        placeholder: "Description",
        value: "",
        label: "Description",
        width: "200px",
        addFormOrder: 2,
        editFormOrder: 2,
        stringType: null,
        header: "Description",
        derivedValue:
          "description=description=description=description=description=message=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "description",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "status",
        fieldName: "status",
        type: "dropDown",
        placeholder: "Status",
        value: "",
        label: "Status",
        width: "120px",
        addFormOrder: 3,
        editFormOrder: 3,
        stringType: null,
        header: "Status",
        derivedValue:
          "status=status=status=status=status=status=status=status=status=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "status",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          {
            label: "New",
            value: "new",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Open",
            value: "open",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Pending",
            value: "pending",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "On Hold",
            value: "onHold",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Resolved",
            value: "resolved",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "No Issue",
            value: "noIssue",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
        ],
        fieldType: "dropDown",
        filterElement: [
          {
            label: "New",
            value: "new",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Open",
            value: "open",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Pending",
            value: "pending",
            color: "warning",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "On Hold",
            value: "onHold",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Resolved",
            value: "resolved",
            color: "success",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "No Issue",
            value: "noIssue",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "status",
        showOrHideFields: [],
        label0: "New",
        dependentField0: "",
        color0: "primary",
        value0: "new",
        label1: "Open",
        dependentField1: "",
        color1: "secondary",
        value1: "open",
        label2: "Pending",
        dependentField2: "",
        color2: "warning",
        value2: "pending",
        label3: "On Hold",
        dependentField3: "",
        color3: "danger",
        value3: "onHold",
        label4: "Resolved",
        dependentField4: "",
        color4: "success",
        value4: "resolved",
        label5: "No Issue",
        dependentField5: "",
        color5: "info",
        value5: "noIssue",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "priority",
        fieldName: "priority",
        type: "dropDown",
        placeholder: "Priority",
        value: "",
        label: "Priority",
        width: "120px",
        addFormOrder: 4,
        editFormOrder: 4,
        stringType: null,
        header: "Priority",
        derivedValue: "priority=priority=priority=priority=priority=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "priority",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          {
            label: "Low",
            value: "low",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Normal",
            value: "normal",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Urgent",
            value: "urgent",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
        ],
        fieldType: "dropDown",
        filterElement: [
          {
            label: "Low",
            value: "low",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Normal",
            value: "normal",
            color: "info",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Urgent",
            value: "urgent",
            color: "danger",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "priority",
        showOrHideFields: [],
        label0: "Low",
        dependentField0: "",
        color0: "primary",
        value0: "low",
        label1: "Normal",
        dependentField1: "",
        color1: "info",
        value1: "normal",
        label2: "Urgent",
        dependentField2: "",
        color2: "danger",
        value2: "urgent",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "comments",
        fieldName: "comments",
        type: "textarea",
        placeholder: "Comments",
        value: "",
        label: "Comments",
        width: "120px",
        addFormOrder: 5,
        editFormOrder: 5,
        stringType: null,
        header: "Comments",
        derivedValue:
          "comments=comments=comments=comments=comments=comments=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "comments",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "comments",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "attachments",
        fieldName: "attachments",
        type: "fileUpload",
        placeholder: "Attachments",
        value: "",
        label: "Attachments",
        width: "120px",
        addFormOrder: 6,
        editFormOrder: 6,
        stringType: null,
        header: "Attachments",
        derivedValue: "attachments=attachments=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: false,
        id: "attachments",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        fieldType: "fileUpload",
        fileType: "img/video",
        maxFileSize: 3,
        isMultipleRequired: true,
        imagePath: "Tickets",
        textAlign: "center",
        show: true,
        field: "attachments",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        fieldName: "createdByName",
        type: "text",
        placeholder: "Created By",
        value: "",
        label: "Created By",
        width: 120,
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Created By",
        derivedValue: "createdByName=createdByName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "createdByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "center",
        show: true,
        field: "createdByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        fieldName: "updatedByName",
        type: "text",
        placeholder: "Updated By",
        value: "",
        label: "Updated By",
        width: 120,
        addFormOrder: 9,
        editFormOrder: 9,
        header: "Updated By",
        derivedValue: "updatedByName=updatedByName=undefined",
        capitalizeTableText: false,
        sortable: false,
        filter: true,
        id: "updatedByName",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        textAlign: "center",
        show: true,
        field: "updatedByName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        fieldName: "created",
        type: "date",
        placeholder: "Created",
        value: "",
        label: "Created",
        width: 90,
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Created",
        derivedValue: "created=created=created=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "created",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        fieldName: "updated",
        type: "date",
        placeholder: "Updated",
        value: "",
        label: "Updated",
        width: 90,
        addFormOrder: 11,
        editFormOrder: 11,
        header: "Updated",
        derivedValue: "updated=updated=updated=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "false",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: false,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "updated",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "true",
        displayineditForm: "true",
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        tDisplay: true,
      },
      {
        name: "officeName",
        fieldName: "officeName",
        type: "relateAutoComplete",
        placeholder: "Office Name",
        value: "",
        label: "Office Name",
        width: "150px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Office Name",
        derivedValue: "officeName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "officeName",
        displayinaddForm: "false",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: 1013,
        isClickable: "",
        isAddToIndex: "",
        isBulkUploadField: false,
        searchField: "name",
        fieldType: "relateAutoComplete",
        populteFields: [],
        controllerName: "Office",
        searchApi: "offices",
        textAlign: "center",
        show: true,
        field: "officeName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    // window.location.reload();
    if (dataTableRef.current) {
      const { getDataFromServer, filterCriteria } = await dataTableRef.current;
      getDataFromServer(filterCriteria);
    }
  };

  const saveDataToServer = async (item, field, value) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.tickets}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  const isEditPermission = rolePermission?.type == "Edit" ? true : false;
  const actionTypes = [
    {
      name: "Delete",
      options: [
        {
          label: "Delete",
          value: "Delete",
          show: rolePermission?.actions?.includes("Delete") ? true : false,
          multiple: true,
        },
      ],
    },
  ];

  return (
    <span>
      {
        <DataTables
          onRef={(ref) => (dataTableRef.current = ref)}
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          viewRequired={isEditPermission}
          exportRequired={false}
          sample={false}
          printRequried={false}
          actionsTypes={actionTypes}
          addRequired={isEditPermission}
          editRequired={isEditPermission}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.tickets}
          globalSearch={'Search'}
          displayName="Tickets"
          type="Tickets"
          routeTo={apiCalls.tickets}
          displayViewOfForm="sideForm"
          sideFormLeftOrRight="right"
          apiResponseKey={apiCalls.tickets?.toLowerCase()}
          apiUrl={apiCalls.tickets}
          selectedId={params.id}
          displayActionsInTable={"actions"}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          // onRef={(ref) => (this.formModalRef = ref)}
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="tickets"
          apiUrl={apiCalls.tickets}
        />
      ) : null}
    </span>
  );
};

export default Tickets;
