import moment from "moment";
import fetchMethodRequest from "./service";
import config from "./config";

export const getAmenities = async () => {
    try {
        const response = await fetchMethodRequest('GET', 'settings/getAmenities');
        if (response && response.respCode) {
            return response.amenities;
        }
        return null;
    } catch (err) {
        return null;
    }
};

export const updateCkEditorValues = (values) => {
    values = values?.replace(/-/g, '');
    const tempElement = document.createElement('div');
    tempElement.innerHTML = values;
    return (tempElement.textContent.replace(/<p>/gi, ' ').replace(/<\/p>/gi, ' '));
};

export const dateAndTimeFormat = (timestamp, tableItem) => {
    if (!timestamp || isNaN(new Date(timestamp).getTime())) return null;

    const formatTime = (hour, minute) => {
        const period = hour >= 12 ? 'PM' : 'AM';
        hour = hour % 12 || 12; // Convert to 12-hour format
        return `${hour.toString().padStart(2, '0')}:${minute} ${period}`;
    };

    let formattedDate, formattedTime;

    if (typeof timestamp === 'string') {
        const [datePart, timePart] = timestamp.split('T');
        const [year, month, day] = datePart.split('-');
        const [hour, minute] = timePart.split(':');
        formattedDate = `${day}-${month}-${year}`;
        formattedTime = formatTime(parseInt(hour, 10), minute);
    } else {
        const localDate = moment.utc(timestamp).local();
        formattedDate = localDate.format('DD-MM-YYYY');
        formattedTime = formatTime(localDate.hours(), localDate.minutes().toString().padStart(2, '0'));
    }

    return (
        <span style={tableItem?.style} title={`${formattedDate} ${formattedTime}`}>
            {tableItem?.fieldUse === "dateAndTime" ? `${formattedDate} ${formattedTime}` : formattedTime}
        </span>
    );
};

export const PropertyAreaOptions = [
    {
        label: "Sq.Ft.",
        value: "sqft",
        color: "success",
    },
    {
        label: "Sq.Yards",
        value: "sqyards",
        color: "danger",
    },
    {
        label: "Sq.M.",
        value: "sqm",
        color: "warning",
    },
    {
        label: "Aankadam",
        value: "aankadam",
        color: "secondary",
    },
    {
        label: "Guntha",
        value: "guntha",
        color: "success",
    },
    {
        label: "Cents",
        value: "cents",
        color: "danger",
    },
    {
        label: "Acres",
        value: "acres",
        color: "info",
    },
    {
        label: "Hectares",
        value: "hectares",
        color: "dark",
    },
    {
        label: "Grounds",
        value: "grounds",
        color: "info",
    },
    {
        label: "Kanal",
        value: "kanal",
        color: "success",
    },
    {
        label: "Rood",
        value: "rood",
        color: "primary",
    },
    {
        label: "Chataks",
        value: "chataks",
        color: "dark",
    },
    {
        label: "Perch",
        value: "perch",
        color: "light",
    },
    {
        label: "Ares",
        value: "ares",
        color: "danger",
    },
    {
        label: "Biswa",
        value: "biswa",
        color: "warning",
    },
    {
        label: "Bigha",
        value: "bigha",
        color: "secondary",
    },
    {
        label: "Kottah",
        value: "kottah",
        color: "primary",
    },
    {
        label: "Marla",
        value: "marla",
        color: "light",
    },
];

const getClientFormFields = () => {
    return [
        {
            name: "firstName",
            fieldName: "firstName",
            type: "text",
            placeholder: "First Name",
            value: "",
            label: "First Name",
            width: "120px",
            addFormOrder: 2,
            editFormOrder: 1,
            stringType: null,
            derivedValue: "firstName=firstName=undefined",
            capitalizeTableText: false,
            sortable: true,
            filter: true,
            id: "firstName",
            displayinaddForm: "true",
            displayineditForm: "true",
            displayinregisterForm: false,
            displayinlist: "false",
            isFieldRequired: "true",
            required: true,
            displayOptionsInActions: "",
            globalSearchField: "true",
            controllerId: null,
            isClickable: "",
            isAddToIndex: false,
            isBulkUploadField: true,
            show: true,
            showOrHideFields: [],
            mobile: true,
            displayInSettings: true,
            fieldType: "Link",
            style: {
                color: "#0e4768",
                cursor: "pointer",
                textTransform: "capitalize",
            },
            isAddFormHidden: false,
            isEditFormHidden: false,
            capitalizeValue: true
        },
        {
            name: "lastName",
            fieldName: "lastName",
            type: "text",
            placeholder: "Last Name",
            value: "",
            label: "Last Name",
            width: "120px",
            addFormOrder: 3,
            editFormOrder: 2,
            stringType: null,
            derivedValue: "lastName=lastName=undefined",
            capitalizeTableText: false,
            sortable: true,
            filter: true,
            id: "lastName",
            displayinaddForm: "true",
            displayineditForm: "true",
            displayinregisterForm: false,
            displayinlist: "false",
            isFieldRequired: "false",
            required: false,
            displayOptionsInActions: "",
            globalSearchField: "true",
            controllerId: null,
            isClickable: "",
            isAddToIndex: false,
            isBulkUploadField: true,
            show: true,
            showOrHideFields: [],
            mobile: true,
            displayInSettings: true,
            isAddFormHidden: false,
            isEditFormHidden: false,
        },
        {
            name: "email",
            fieldName: "email",
            type: "email",
            placeholder: "Email",
            value: "",
            label: "Email",
            width: "120px",
            addFormOrder: 4,
            editFormOrder: 3,
            stringType: null,
            derivedValue: "email=undefined",
            capitalizeTableText: false,
            sortable: true,
            filter: true,
            id: "email",
            displayinaddForm: "true",
            displayineditForm: "true",
            displayinregisterForm: false,
            displayinlist: "true",
            isFieldRequired: "false",
            required: false,
            displayOptionsInActions: "",
            globalSearchField: "true",
            controllerId: null,
            isClickable: "",
            isAddToIndex: false,
            isBulkUploadField: true,
            show: true,
            showOrHideFields: [],
            mobile: true,
            displayInSettings: true,
            isAddFormHidden: false,
            isEditFormHidden: false,
        },
        {
            name: "phoneNumber",
            fieldName: "phoneNumber",
            type: "text",
            placeholder: "Phone Number",
            value: "",
            label: "Phone Number",
            width: "120px",
            addFormOrder: 5,
            editFormOrder: 4,
            stringType: null,
            derivedValue: "phoneNumber=undefined",
            capitalizeTableText: false,
            sortable: true,
            filter: true,
            id: "phoneNumber",
            displayinaddForm: "true",
            displayineditForm: "true",
            displayinregisterForm: false,
            displayinlist: "true",
            isFieldRequired: "false",
            required: false,
            displayOptionsInActions: "",
            globalSearchField: "true",
            controllerId: null,
            isClickable: "",
            isAddToIndex: false,
            isBulkUploadField: true,
            show: true,
            showOrHideFields: [],
            mobile: true,
            displayInSettings: true,
            isAddFormHidden: false,
            isEditFormHidden: false,
        },
        {
            name: "type",
            fieldName: "type",
            type: "checkbox",
            placeholder: "Type",
            value: ['Seller'],
            label: "Type",
            width: "120px",
            addFormOrder: 7,
            editFormOrder: 7,
            stringType: null,
            derivedValue: "type=undefined",
            capitalizeTableText: false,
            sortable: false,
            filter: true,
            id: "type",
            displayinaddForm: "true",
            displayineditForm: "true",
            displayinregisterForm: "",
            displayinlist: "true",
            isFieldRequired: "false",
            required: false,
            displayOptionsInActions: "",
            globalSearchField: "true",
            controllerId: null,
            isClickable: "",
            isAddToIndex: "",
            isBulkUploadField: true,
            show: true,
            showOrHideFields: [],
            options: [
                { label: 'Buyer', value: 'Buyer' },
                { label: 'Seller', value: 'Seller' },
            ],
            multiple: true,
            multipleSelect: false,
            mobile: true,
            displayInSettings: true,
            isAddFormHidden: false,
            isEditFormHidden: false,
            optionsWidth: 'col-sm-4'
        },
        {
            name: "notes",
            fieldName: "notes",
            type: "textarea",
            placeholder: "Notes",
            value: "",
            label: "Notes",
            width: "120px",
            addFormOrder: 6,
            editFormOrder: 5,
            stringType: null,
            derivedValue: "notes=notes=undefined",
            capitalizeTableText: false,
            sortable: false,
            filter: false,
            id: "notes",
            displayinaddForm: "true",
            displayineditForm: "true",
            displayinregisterForm: "",
            displayinlist: "false",
            isFieldRequired: "false",
            required: false,
            displayOptionsInActions: "",
            globalSearchField: "true",
            controllerId: null,
            isClickable: "",
            isAddToIndex: false,
            isBulkUploadField: true,
            show: true,
            showOrHideFields: [],
            mobile: true,
            displayInSettings: true,
            isAddFormHidden: false,
            isEditFormHidden: false,
        },
        {
            name: "photo",
            fieldName: "photo",
            type: "fileUpload",
            placeholder: "Photo",
            value: "",
            label: "Photo",
            width: "120px",
            addFormOrder: 8,
            editFormOrder: 6,
            stringType: null,
            derivedValue: "photo=photo=undefined",
            capitalizeTableText: false,
            sortable: false,
            filter: false,
            id: "photo",
            displayinaddForm: "true",
            displayineditForm: "true",
            displayinregisterForm: "",
            displayinlist: "false",
            isFieldRequired: "false",
            required: false,
            displayOptionsInActions: "",
            globalSearchField: "true",
            controllerId: null,
            isClickable: "",
            isAddToIndex: "",
            isBulkUploadField: false,
            fieldType: "fileUpload",
            fileType: "img/video",
            maxFileSize: 10,
            acceptType: "image/*",
            isMultipleRequired: false,
            imagePath: "Clients",
            show: true,
            showOrHideFields: [],
            mobile: true,
            displayInSettings: true,
            isAddFormHidden: false,
            isEditFormHidden: false,
        },
        {
            name: "name",
            fieldName: "name",
            type: "text",
            placeholder: "Name",
            value: "",
            label: "Name",
            width: 110,
            addFormOrder: 1,
            editFormOrder: 10,
            derivedValue: "name=name=name=undefined",
            capitalizeTableText: false,
            sortable: false,
            filter: false,
            id: "name",
            displayinaddForm: "false",
            displayineditForm: "false",
            displayinregisterForm: false,
            displayinlist: "false",
            isFieldRequired: "false",
            required: false,
            displayOptionsInActions: "",
            globalSearchField: "true",
            controllerId: null,
            isClickable: "",
            isAddToIndex: false,
            isBulkUploadField: true,
            show: true,
            showOrHideFields: [],
            mobile: true,
            displayInSettings: true,
            isAddFormHidden: true,
            isEditFormHidden: true,
        },
        {
            name: "createdByName",
            fieldName: "createdByName",
            type: "text",
            placeholder: "Created By",
            value: "",
            label: "Created By",
            width: 120,
            addFormOrder: 10,
            editFormOrder: 10,
            derivedValue: "createdByName=undefined",
            capitalizeTableText: false,
            sortable: false,
            filter: true,
            id: "createdByName",
            displayinaddForm: "false",
            displayineditForm: "false",
            displayinregisterForm: false,
            displayinlist: "false",
            isFieldRequired: "false",
            required: false,
            displayOptionsInActions: "",
            globalSearchField: "false",
            controllerId: "employee",
            isClickable: "",
            isAddToIndex: false,
            isBulkUploadField: false,
            show: true,
            showOrHideFields: [],
            mobile: true,
            displayInSettings: true,
            isAddFormHidden: true,
            isEditFormHidden: true,
        },
        {
            name: "updatedByName",
            fieldName: "updatedByName",
            type: "text",
            placeholder: "Updated By",
            value: "",
            label: "Updated By",
            width: 120,
            addFormOrder: 9,
            editFormOrder: 10,
            derivedValue: "updatedByName=updatedByName=undefined",
            capitalizeTableText: false,
            sortable: false,
            filter: true,
            id: "updatedByName",
            displayinaddForm: "false",
            displayineditForm: "false",
            displayinregisterForm: false,
            displayinlist: "false",
            isFieldRequired: "false",
            required: false,
            displayOptionsInActions: "",
            globalSearchField: "false",
            controllerId: null,
            isClickable: "",
            isAddToIndex: false,
            isBulkUploadField: false,
            show: true,
            showOrHideFields: [],
            mobile: true,
            displayInSettings: true,
            isAddFormHidden: true,
            isEditFormHidden: true,
        },
        {
            name: "updated",
            fieldName: "updated",
            type: "date",
            placeholder: "Updated",
            value: "",
            label: "Updated",
            width: 90,
            addFormOrder: 10,
            editFormOrder: 10,
            derivedValue: "updated=updated=updated=undefined",
            capitalizeTableText: "",
            sortable: "",
            filter: false,
            actions: [],
            actionsNumber: [],
            id: "updated",
            displayinaddForm: "false",
            displayineditForm: "false",
            displayinregisterForm: "",
            displayinlist: "false",
            isFieldRequired: "false",
            required: false,
            displayOptionsInActions: "",
            globalSearchField: "false",
            controllerId: null,
            isClickable: "",
            isAddToIndex: false,
            isBulkUploadField: false,
            dateFormat: "YYYY-MM-DD HH:mm:ss",
            fieldType: "Date",
            show: true,
            showOrHideFields: [],
            mobile: true,
            displayInSettings: true,
            isAddFormHidden: true,
            isEditFormHidden: true,
        },
        {
            name: "created",
            fieldName: "created",
            type: "date",
            placeholder: "Created",
            value: "",
            label: "Created",
            width: 90,
            addFormOrder: 10,
            editFormOrder: 10,
            derivedValue: "created=created=undefined",
            capitalizeTableText: "",
            sortable: "",
            filter: false,
            actions: [],
            actionsNumber: [],
            id: "created",
            displayinaddForm: "false",
            displayineditForm: "false",
            displayinregisterForm: "",
            displayinlist: "false",
            isFieldRequired: "false",
            required: false,
            displayOptionsInActions: "",
            globalSearchField: "false",
            controllerId: null,
            isClickable: "",
            isAddToIndex: false,
            isBulkUploadField: false,
            dateFormat: "YYYY-MM-DD HH:mm:ss",
            fieldType: "Date",
            show: true,
            showOrHideFields: [],
            mobile: true,
            displayInSettings: true,
            isAddFormHidden: true,
            isEditFormHidden: true,
        },
    ];
}

export default getClientFormFields;


export const SoldPriceOptions = [
    { "label": "5 Lakhs", "value": 500000 },
    { "label": "10 Lakhs", "value": 1000000 },
    { "label": "15 Lakhs", "value": 1500000 },
    { "label": "20 Lakhs", "value": 2000000 },
    { "label": "25 Lakhs", "value": 2500000 },
    { "label": "30 Lakhs", "value": 3000000 },
    { "label": "35 Lakhs", "value": 3500000 },
    { "label": "40 Lakhs", "value": 4000000 },
    { "label": "45 Lakhs", "value": 4500000 },
    { "label": "50 Lakhs", "value": 5000000 },
    { "label": "55 Lakhs", "value": 5500000 },
    { "label": "60 Lakhs", "value": 6000000 },
    { "label": "65 Lakhs", "value": 6500000 },
    { "label": "70 Lakhs", "value": 7000000 },
    { "label": "75 Lakhs", "value": 7500000 },
    { "label": "80 Lakhs", "value": 8000000 },
    { "label": "85 Lakhs", "value": 8500000 },
    { "label": "90 Lakhs", "value": 9000000 },
    { "label": "95 Lakhs", "value": 9500000 },
    { "label": "1 Cr", "value": 10000000 },
    { "label": "1.05 Crs", "value": 10500000 },
    { "label": "1.1 Crs", "value": 11000000 },
    { "label": "1.15 Crs", "value": 11500000 },
    { "label": "1.2 Crs", "value": 12000000 },
    { "label": "1.25 Crs", "value": 12500000 },
    { "label": "1.3 Crs", "value": 13000000 },
    { "label": "1.35 Crs", "value": 13500000 },
    { "label": "1.4 Crs", "value": 14000000 },
    { "label": "1.45 Crs", "value": 14500000 },
    { "label": "1.5 Crs", "value": 15000000 },
    { "label": "1.55 Crs", "value": 15500000 },
    { "label": "1.6 Crs", "value": 16000000 },
    { "label": "1.65 Crs", "value": 16500000 },
    { "label": "1.7 Crs", "value": 17000000 },
    { "label": "1.75 Crs", "value": 17500000 },
    { "label": "1.8 Crs", "value": 18000000 },
    { "label": "1.85 Crs", "value": 18500000 },
    { "label": "1.9 Crs", "value": 19000000 },
    { "label": "2 Crs", "value": 20000000 },
    { "label": "2.1 Crs", "value": 21000000 },
    { "label": "2.2 Crs", "value": 22000000 },
    { "label": "2.3 Crs", "value": 23000000 },
    { "label": "2.4 Crs", "value": 24000000 },
    { "label": "2.5 Crs", "value": 25000000 },
    { "label": "2.6 Crs", "value": 26000000 },
    { "label": "2.7 Crs", "value": 27000000 },
    { "label": "2.8 Crs", "value": 28000000 },
    { "label": "2.9 Crs", "value": 29000000 },
    { "label": "3 Crs", "value": 30000000 },
    { "label": "3.1 Crs", "value": 31000000 },
    { "label": "3.2 Crs", "value": 32000000 },
    { "label": "3.3 Crs", "value": 33000000 },
    { "label": "3.4 Crs", "value": 34000000 },
    { "label": "3.5 Crs", "value": 35000000 },
    { "label": "3.6 Crs", "value": 36000000 },
    { "label": "3.7 Crs", "value": 37000000 },
    { "label": "3.8 Crs", "value": 38000000 },
    { "label": "3.9 Crs", "value": 39000000 },
    { "label": "4 Crs", "value": 40000000 },
    { "label": "4.1 Crs", "value": 41000000 },
    { "label": "4.2 Crs", "value": 42000000 },
    { "label": "4.3 Crs", "value": 43000000 },
    { "label": "4.4 Crs", "value": 44000000 },
    { "label": "4.5 Crs", "value": 45000000 },
    { "label": "4.6 Crs", "value": 46000000 },
    { "label": "4.7 Crs", "value": 47000000 },
    { "label": "4.8 Crs", "value": 48000000 },
    { "label": "4.9 Crs", "value": 49000000 },
    { "label": "5 Crs", "value": 50000000 },
    { "label": "5.25 Crs", "value": 52500000 },
    { "label": "5.5 Crs", "value": 55000000 },
    { "label": "5.75 Crs", "value": 57500000 },
    { "label": "6 Crs", "value": 60000000 },
    { "label": "6.25 Crs", "value": 62500000 },
    { "label": "6.5 Crs", "value": 65000000 },
    { "label": "6.75 Crs", "value": 67500000 },
    { "label": "7 Crs", "value": 70000000 },
    { "label": "7.25 Crs", "value": 72500000 },
    { "label": "7.5 Crs", "value": 75000000 },
    { "label": "7.75 Crs", "value": 77500000 },
    { "label": "8 Crs", "value": 80000000 },
    { "label": "8.25 Crs", "value": 82500000 },
    { "label": "8.5 Crs", "value": 85000000 },
    { "label": "8.75 Crs", "value": 87500000 },
    { "label": "9 Crs", "value": 90000000 },
    { "label": "9.25 Crs", "value": 92500000 },
    { "label": "9.5 Crs", "value": 95000000 },
    { "label": "9.75 Crs", "value": 97500000 },
    { "label": "10 Crs", "value": 100000000 },
    { "label": "20 Crs", "value": 200000000 },
    { "label": "30 Crs", "value": 300000000 },
    { "label": "40 Crs", "value": 400000000 },
    { "label": "50 Crs", "value": 500000000 },
    { "label": "60 Crs", "value": 600000000 },
    { "label": "70 Crs", "value": 700000000 },
    { "label": "80 Crs", "value": 800000000 },
    { "label": "90 Crs", "value": 900000000 },
    { "label": "100 Crs", "value": 1000000000 },
    { "label": "100+ Crs", "value": 1000000000 }
];