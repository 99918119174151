import React, { useEffect, useState } from 'react';
import DataTables from '../../CommonDataTable/DataTable';
import config from '../../../../config/config';
import apiCalls from '../../../../config/apiCalls'
import store from '../../../App/store';
import RolePermissions from '../../CommonModals/Permissions';
import ViewModal from '../../CommonModals/viewModal';
import { useParams } from 'react-router';
// config file
const Activities = (props) => {
	
	const [type,setType] = useState('Activities');
	const [rolePermission,setRolePermission] = useState('Activities');

	const params = useParams();
	const getDerivedStateFromProps = (props, state) => {
		let storeData = store.getState()
		let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
		return { languageData: languageData };
	}

	const actionTypes = [
		{
			name: "Delete",
			options: [
				{
					label: "Delete",
					value: "Delete",
					show: rolePermission?.actions?.includes("Delete") ? true : false,
					multiple: true,
				},
			],
		},
	]
	
	useEffect(()=> {
		let screenPermissions = RolePermissions.screenPermissions('Activities');
		if (screenPermissions) {		
			setRolePermission(screenPermissions);
		}

	},[])

	const getTableFields = () => {	
		let data =[
			{
				textAlign: "center",
				width: 47,
				field: "Sno",
				header: "S.No",
				label: "Sno",
				filter: false,
				sortable: false,
				mobile: true,
				placeholder: "Search",
				show: true,
				displayInSettings: true,
			},
			{
				name: "officeObjId",
				fieldName: "officeObjId",
				type: "relateAutoComplete",
				placeholder: "Office Name",
				value: "",
				label: "Office Name",
				width: "100px",
				addFormOrder: 10,
				editFormOrder: 10,
				stringType: null,
				header: "Office Name",
				derivedValue: "officeName=undefined",
				capitalizeTableText: false,
				sortable: true,
				filter: true,
				id: "officeName",
				displayinaddForm: "false",
				displayineditForm: "false",
				displayinregisterForm: "",
				displayinlist: "true",
				isFieldRequired: "false",
				required: false,
				displayOptionsInActions: "",
				globalSearchField: "true",
				controllerId: 1013,
				isClickable: "",
				isAddToIndex: "",
				isBulkUploadField: false,
				searchField: "name",
				fieldType: "relateAutoComplete",
				populteFields: [],
				controllerName: "Office",
				searchApi: "offices",
				textAlign: "left",
				show: true,
				field: "officeObjId",
				showOrHideFields: [],
				mobile: true,
				displayInSettings: true,
				tDisplay: true,
				isMultiple: false
			},
			{
				name: "created",
				textAlign: "center",
				show: true,
				mobile: true,
				width: 80,
				field: "created",
				label: "Created",
				fieldType: "Date",
				type:'date',
				dateFormat: config.fullDateFormat,
				header: "Created",
				filter: true,
				sortable: true,
				displayInSettings: true,
			}, 
			{
				name: "agentObjId",
				fieldName: "agentObjId",
				type: "relateAutoComplete",
				placeholder: "Office Name",
				value: "",
				label: "Office Name",
				width: "100px",
				addFormOrder: 10,
				editFormOrder: 10,
				stringType: null,
				header: "Agent",
				derivedValue: "officeName=undefined",
				capitalizeTableText: false,
				sortable: true,
				filter: true,
				id: "officeName",
				displayinaddForm: "false",
				displayineditForm: "false",
				displayinregisterForm: "",
				displayinlist: "true",
				isFieldRequired: "false",
				required: false,
				displayOptionsInActions: "",
				globalSearchField: "true",
				controllerId: 1013,
				isClickable: "",
				isAddToIndex: "",
				isBulkUploadField: false,
				searchField: "firstName",
				fieldType: "relateAutoComplete",
				populteFields: [],
				controllerName: "Office",
				searchApi: "fullName",
				textAlign: "left",
				show: true,
				field: "agentObjId",
				showOrHideFields: [],
				mobile: true,
				displayInSettings: true,
				tDisplay: true,
				isMultiple: false
			},
			{
				name: "context",
				textAlign: "left",
				width: 80,
				field: "context",
				mobile: true,
				header: "Context",
				label: "Context",
				filter: true,
				sortable: true,
				show: true,
				textCapitalize: true,
				displayInSettings: true,
			},
			{
				name: "contextType",
				textAlign: "left",
				width: 80,
				field: "contextType",
				mobile: true,
				label: "Context Type",
				header: "Context Type",
				filter: true,
				sortable: true,
				show: true,
				displayInSettings: true,
			},
			{
				name: "email",
				textAlign: "left",
				width: 80,
				field: "email",
				mobile: true,
				label: "Email",
				header: "Email / Phone",
				filter: true,
				sortable: true,
				show: true,
				displayInSettings: true,
				fieldType: "Link",
			},
			// {
			// 	name: "phoneNumber",
			// 	textAlign: "left",
			// 	width: 110,
			// 	field: "phoneNumber",
			// 	mobile: true,
			// 	label: "Phone Number",
			// 	header: "Phone Number",
			// 	filter: true,
			// 	sortable: true,
			// 	show: true,
			// 	displayInSettings: true,
			// },
			{
				name: "description",
				show: true,
				textAlign: "left",
				width: 80,
				mobile: true,
				field: "description",
				header: "Description",
				filter: true,
				label: "Description",
				sortable: true,
				textCapitalize: true,
				displayInSettings: true,
			},
			{
				name: "ipAddress",
				textAlign: "left",
				width: 80,
				field: "ipAddress",
				label: "Ip Address",
				mobile: true,
				header: 'Ip Address',
				filter: true,
				sortable: true,
				show: true,
				displayInSettings: true,
			},
			{
				name: "deviceType",
				textAlign: "left",
				width: 80,
				field: "deviceType",
				label: "Device Type",
				mobile: true,
				header: 'Device Type',
				filter: true,
				sortable: true,
				show: true,
				displayInSettings: true,
			},
			{
				name: "browserName",
				textAlign: "left",
				width: 80,
				field: "browserName",
				label: "Browser Name",
				mobile: true,
				header: 'Browser Name',
				filter: true,
				sortable: true,
				show: true,
				displayInSettings: true,
			},
			{
				name: 'osName',
				textAlign: "left",
				width: 80,
				field: 'osName',
				label: "Os Name",
				mobile: true,
				header: 'Os Name',
				filter: true,
				sortable: true,
				show: true,
				displayInSettings: true,
				"type": "dropDown",
				"fieldType": "dropDown",
				"filterElement": [
					{
						"label": "Windows",
						"value": "Windows",
						"color": "primary"
					},
					{
						"label": "Linux",
						"value": "Linux",
						"color": "primary"
					}
				],
				"options": [
					{
						"label": "Windows",
						"value": "Windows",
						"color": "primary"
					},
					{
						"label": "Linux",
						"value": "Linux",
						"color": "primary"
					}
				]
			},
			{
				name: 'osVersion',
				textAlign: "left",
				width: 80,
				field: 'osVersion',
				label: "Os Version",
				mobile: true,
				header: 'Os Version',
				filter: false,
				sortable: true,
				show: true,
				displayInSettings: true,
			},
		]

		return data;
	};


	const getFormFields = () => {
		return ([
			{
				'show': false,
				"value": "",
				"type": "text",
				"name": "firstName",
				"label": "First Name",
				"id": "firstName",
				"placeholder": "First Name",
				"required": true
			},
			
			
		]);
	}

		return (
			<span>
				<DataTables
					getTableFields={getTableFields}
					formFields={getFormFields}
					actionsTypes={[]}
					exportRequired={true}
					printRequried={false}
					addRequried={false}
					editRequired={false}
					deleteRequired={false}
					viewRequired={false}
					settingsRequired={true}
					filterRequired={false}
					gridRequried={false}
					sample={false}
					globalSearchFieldName='activity'
					globalSearch={"context"}
					type="Activities"
					displayName='Activities'
					apiResponseKey={"activities"}
					apiUrl={"activities"}
					routeTo='activities'
					displayViewOfForm='sideForm'
					sideFormLeftOrRight="right"
					selectedId={params.id}	
					screenHeader={'Activity'}		 
					hideActions={true}
				/>
			</span>
		);
	
}

export default Activities;