import React, { useEffect, useState, useRef } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
// config file

const ScheduleAppointments = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);

  let dataTableRef = useRef(null);
  const params = useParams();
  useEffect(() => {
    let screenPermissions = RolePermissions.screenPermissions(
      "Appointments"
    );
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }

    
  }, []);

 
  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
      storeData.settingsData &&
      storeData.settingsData.settings &&
      storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const getTableFields = () => {
    let roleTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Admin",
        value: "Admin",
      },
      {
        label: "Super Admin",
        value: "Super Admin",
      },
      {
        label: "User",
        value: "User",
      },
    ];
    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    let data = [
      // {
      //   textAlign: "center",
      //   width: 47,
      //   field: "Check Box",
      //   label: "Check Box",
      //   fieldType: "multiple",
      //   header: "",
      //   selectionMode: "multiple",
      //   show: true,
      //   mobile: true,
      //   displayInSettings: true,
      //   displayinlist: "false",
      //   displayinaddForm: "false",
      //   displayineditForm: "false",
      //   globalSearchField: "false",
      //   isFieldRequired: "false",
      //   tDisplay: true,
      // },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "S.No",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "appointmentFor",
        fieldName: "appointmentFor",
        type: "radio",
        placeholder: "Appointment For",
        value: "client",
        label: "Appointment For",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Appointment For",
        derivedValue: "appointmentFor=appointmentFor=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "appointmentFor",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: true,
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "Client", value: "client", id: "msr" },
          { label: "Other", value: "other", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "Client", value: "client", id: "msr" },
          { label: "Other", value: "other", id: "msr" },
        ],
        textAlign: "center",
        show: true,
        field: "appointmentFor",
        showOrHideFields: [],
        label0: "Client",
        value0: "client",
        label1: "Other",
        value1: "other",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "nameSearch",
        fieldName: "nameSearch",
        type: "text",
        placeholder: "Client Name",
        value: "",
        label: "Client Name",
        width: "120px",
        addFormOrder: 2,
        editFormOrder: 1,
        stringType: null,
        header: "Client Name",
        derivedValue: "firstName=firstName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "nameSearch",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: true,
        field: "nameSearch",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "appointmentDate",
        fieldName: "appointmentDate",
        type: "date",
        placeholder: "Appointment Date",
        value: "",
        label: "Appointment Date",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Appointment Date",
        derivedValue: "appointmentDate=appointmentDate=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: true,
        id: "appointmentDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "Center",
        show: true,
        field: "appointmentDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "timeSearch",
        fieldName: "timeSearch",
        type: "text",
        placeholder: "Time",
        label: "Time",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Time",
        derivedValue: "time=time=timeSlot=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "time",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        
        dependent: [],
        textAlign: "center",
        show: true,
        field: "timeSearch",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "appointmentType",
        fieldName: "appointmentType",
        type: "dropDown",
        placeholder: "Appointment Type",
        value: "",
        label: "Appointment Type",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Appointment Type",
        derivedValue: "appointmentType=appointmentType=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "appointmentType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          {
            label: "Viewing",
            value: "viewing",
            color: "",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Meeting",
            value: "meeting",
            color: "",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Consultation",
            value: "consultation",
            color: "",
            dependentField: "",
            id: "dpc",
          },
        ],
        fieldType: "dropDown",
        filterElement: [
          {
            label: "Viewing",
            value: "viewing",
            color: "",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Meeting",
            value: "meeting",
            color: "",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Consultation",
            value: "consultation",
            color: "",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "appointmentType",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "notes",
        fieldName: "notes",
        type: "textarea",
        placeholder: "Notes",
        value: "",
        label: "Notes",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Notes",
        derivedValue: "notes=notes=undefined",
        capitalizeTableText: true,
        sortable: false,
        filter: true,
        id: "notes",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "left",
        show: true,
        field: "notes",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "left",
        displayinregisterForm: false,
        isBulkUploadField: false,
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "left",
        displayinregisterForm: false,
        globalSearchField: "false",
        isBulkUploadField: false,
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.dateDayMonthFormat,
        textAlign: "Center",
        isBulkUploadField: false,
        disabled: true,
        show: true,
        field: "created",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.dateDayMonthFormat,
        textAlign: "Center",
        isBulkUploadField: false,
        disabled: true,
        show: true,
        field: "updated",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      // {
      //   show: true,
      //   textAlign: "center",
      //   width: 60,
      //   fieldType: "Actions",
      //   field: "Actions",
      //   header: "Actions",
      //   label: "Actions",
      //   filter: false,
      //   sortable: false,
      //   displayInSettings: true,
      //   displayinServer: "false",
      //   displayinlist: "true",
      //   displayinaddForm: "true",
      //   displayineditForm: "true",
      //   globalSearchField: "false",
      //   isFieldRequired: "false",
      //   mobile: true,
      //   tDisplay: true,
      // },
    ];
    return data;
  };

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    return [
      {
        name: "appointmentFor",
        fieldName: "appointmentFor",
        type: "radio",
        placeholder: "Appointment For",
        value: "client",
        label: "Appointment For",
        width: "120px",
        addFormOrder: 1,
        editFormOrder: 1,
        derivedValue: "appointmentFor=appointmentFor=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "appointmentFor",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: true,
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "Client", value: "client", id: "msr" },
          { label: "Other", value: "other", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "Client", value: "client", id: "msr" },
          { label: "Other", value: "other", id: "msr" },
        ],
        show: true,
        showOrHideFields: [],
        label0: "Client",
        value0: "client",
        label1: "Other",
        value1: "other",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
        dependent: [{"client": ["clientName"]}, {"other": ["otherName"]}]
      },
      {
        name: "clientName",
        fieldName: "clientName",
        type: "relateAutoComplete",
        placeholder: "Name",
        value: "",
        label: "Name",
        width: "120px",
        addFormOrder: 2,
        editFormOrder: 2,
        stringType: null,
        derivedValue: "clientName=clientName=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "clientName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: 1008,
        isClickable: true,
        isAddToIndex: "",
        isBulkUploadField: false,
        hasDependency: false,
        searchField: "firstName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        controllerName: "Clients",
        searchApi: "clients",
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "otherName",
        fieldName: "otherName",
        type: "text",
        placeholder: "Name",
        value: "",
        label: "Name",
        width: "150px",
        addFormOrder: 2,
        editFormOrder: 2,
        stringType: null,
        derivedValue: "builderName=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        // required:true,
        id: "builderName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: false,
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: false,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "appointmentDate",
        fieldName: "appointmentDate",
        type: "date",
        placeholder: "Appointment Date",
        value: "",
        label: "Appointment Date",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        derivedValue: "appointmentDate=appointmentDate=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: true,
        id: "appointmentDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "time",
        fieldName: "time",
        type: "relateAutoComplete",
        placeholder: "Time",
        value: "",
        label: "Time",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        derivedValue: "time=time=timeSlot=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "time",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        fieldType: "relateAutoComplete",
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "appointmentType",
        fieldName: "appointmentType",
        type: "dropDown",
        placeholder: "Appointment Type",
        value: "",
        label: "Appointment Type",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        derivedValue: "appointmentType=appointmentType=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "appointmentType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          {
            label: "Viewing",
            value: "viewing",
            color: "",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Meeting",
            value: "meeting",
            color: "",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Consultation",
            value: "consultation",
            color: "",
            dependentField: "",
            id: "dpc",
          },
        ],
        fieldType: "dropDown",
        filterElement: [
          {
            label: "Viewing",
            value: "viewing",
            color: "",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Meeting",
            value: "meeting",
            color: "",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Consultation",
            value: "consultation",
            color: "",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "notes",
        fieldName: "notes",
        type: "textarea",
        placeholder: "Notes",
        value: "",
        label: "Notes",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        derivedValue: "notes=notes=undefined",
        capitalizeTableText: true,
        sortable: false,
        filter: true,
        id: "notes",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "createdByName",
        type: "text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        displayinregisterForm: false,
        isBulkUploadField: false,
        show: true,
        mobile: true,
        filter: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        isFieldRequired: "false",
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "updatedByName",
        type: "text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        displayinregisterForm: false,
        globalSearchField: "false",
        isBulkUploadField: false,
        show: true,
        filter: true,
        mobile: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        isFieldRequired: "false",
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        isBulkUploadField: false,
        disabled: true,
        show: true,
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        isBulkUploadField: false,
        disabled: true,
        show: true,
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
    console.log("Submit Button in sode          ");
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        displayinlist: "false",
        displayinaddForm: "false",
        displayineditForm: "false",
        globalSearchField: "false",
        isFieldRequired: "false",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "appointmentFor",
        fieldName: "appointmentFor",
        type: "radio",
        placeholder: "Appointment For",
        value: "client",
        label: "Appointment For",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        header: "Appointment For",
        derivedValue: "appointmentFor=appointmentFor=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "appointmentFor",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: true,
        isAddToIndex: "",
        isBulkUploadField: true,
        options: [
          { label: "Client", value: "client", id: "msr" },
          { label: "Other", value: "other", id: "msr" },
        ],
        fieldType: "radio",
        filterElement: [
          { label: "Client", value: "client", id: "msr" },
          { label: "Other", value: "other", id: "msr" },
        ],
        textAlign: "center",
        show: true,
        field: "appointmentFor",
        showOrHideFields: [],
        label0: "Client",
        value0: "client",
        label1: "Other",
        value1: "other",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "clientName",
        fieldName: "clientName",
        type: "relateAutoComplete",
        placeholder: "Client Name",
        value: "",
        label: "Client Name",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Client Name",
        derivedValue: "clientName=clientName=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "clientName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: 1008,
        isClickable: true,
        isAddToIndex: "",
        isBulkUploadField: false,
        hasDependency: false,
        searchField: "firstName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        controllerName: "Clients",
        searchApi: "clients",
        textAlign: "center",
        show: true,
        field: "clientName",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "appointmentDate",
        fieldName: "appointmentDate",
        type: "date",
        placeholder: "Appointment Date",
        value: "",
        label: "Appointment Date",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Appointment Date",
        derivedValue: "appointmentDate=appointmentDate=undefined",
        capitalizeTableText: "",
        sortable: "",
        filter: true,
        id: "appointmentDate",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        dateFormat: "DD-MM-YYYY",
        fieldType: "Date",
        textAlign: "center",
        show: true,
        field: "appointmentDate",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "time",
        fieldName: "time",
        type: "relateAutoComplete",
        placeholder: "Time",
        value: "",
        label: "Time",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Time",
        derivedValue: "time=time=timeSlot=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "time",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          {
            label: "00:00",
            value: "0",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "01:00",
            value: "1",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
        ],
        fieldType: "dropDown",
        filterElement: [
          {
            label: "00:00",
            value: "0",
            color: "primary",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "01:00",
            value: "1",
            color: "secondary",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "time",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "appointmentType",
        fieldName: "appointmentType",
        type: "dropDown",
        placeholder: "Appointment Type",
        value: "",
        label: "Appointment Type",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Appointment Type",
        derivedValue: "appointmentType=appointmentType=undefined",
        capitalizeTableText: true,
        sortable: true,
        filter: true,
        id: "appointmentType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        options: [
          {
            label: "Viewing",
            value: "viewing",
            color: "",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Meeting",
            value: "meeting",
            color: "",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Consultation",
            value: "consultation",
            color: "",
            dependentField: "",
            id: "dpc",
          },
        ],
        fieldType: "dropDown",
        filterElement: [
          {
            label: "Viewing",
            value: "viewing",
            color: "",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Meeting",
            value: "meeting",
            color: "",
            dependentField: "",
            id: "dpc",
          },
          {
            label: "Consultation",
            value: "consultation",
            color: "",
            dependentField: "",
            id: "dpc",
          },
        ],
        dependent: [],
        textAlign: "center",
        show: true,
        field: "appointmentType",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "notes",
        fieldName: "notes",
        type: "textarea",
        placeholder: "Notes",
        value: "",
        label: "Notes",
        width: "120px",
        addFormOrder: 10,
        editFormOrder: 10,
        stringType: null,
        header: "Notes",
        derivedValue: "notes=notes=undefined",
        capitalizeTableText: true,
        sortable: false,
        filter: true,
        id: "notes",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinregisterForm: "",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: "",
        globalSearchField: "true",
        controllerId: null,
        isClickable: "",
        isAddToIndex: false,
        isBulkUploadField: true,
        textAlign: "center",
        show: true,
        field: "notes",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        displayinregisterForm: false,
        isBulkUploadField: false,
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        displayinregisterForm: false,
        globalSearchField: "false",
        isBulkUploadField: false,
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        displayinaddForm: "false",
        displayineditForm: "false",
        isFieldRequired: "false",
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        isBulkUploadField: false,
        disabled: true,
        show: true,
        field: "created",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        isBulkUploadField: false,
        disabled: true,
        show: true,
        field: "updated",
        isFieldRequired: "false",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "true",
        displayineditForm: "true",
        globalSearchField: "false",
        isFieldRequired: "false",
        mobile: true,
        tDisplay: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    // window.location.reload();
    if (dataTableRef.current) {
      const { getDataFromServer, filterCriteria } = await dataTableRef.current;
      getDataFromServer(filterCriteria);
    }
  };

  const saveDataToServer = async (item, field, value) => {
    let userBody;
    if (item?.length > 0 && field && value) {
      let selectedIds = item.map((row) => row._id);
      userBody = {
        selectedIds: selectedIds,
        updatedDetails: { [field]: value },
      };
    } else {
      return;
    }
    if (userBody) {
      let method, apiUrl;
      method = "POST";
      apiUrl = `${apiCalls.scheduleAppointments}/multiUpdate`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };

  const isEditPermission = rolePermission?.type == "Edit" ? true : false;
  const actionTypes = [
    {
      name: "Delete",
      options: [
        {
          label: "Delete",
          value: "Delete",
          show: rolePermission?.actions?.includes("Delete") ? true : false,
          multiple: true,
        },
      ],
    },
  ];

  return (
    <span>
      {
        <DataTables
          onRef={(ref) => (dataTableRef.current = ref)}
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          //
          addRequired={isEditPermission}
          editRequired={isEditPermission}
          deleteRequired={isEditPermission}
          viewRequired={isEditPermission}
          exportRequired={false}
          // sample={false}
          // globalSearch={'Display Name/Email'}
          // type='ScheduleAppointments'
          // apiUrl={apiCalls.ScheduleAppointments}
          // exportRequried={true}
          // printRequried={true}
          // actionsTypes={actionTypes}
          // addRequried={insertAdd}
          // editRequired={true}
          // deleteRequired={true}
          // viewRequired={true}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.scheduleAppointments}
          globalSearch={
            "appointmentFor/clientName/appointmentDate/time/appointmentType/notes"
          }
          displayName="Appointments"
          screenHeader="Schedule Appointments"
          type="ScheduleAppointments"
          routeTo={apiCalls.scheduleAppointments}
          displayViewOfForm="sideForm"
          sideFormLeftOrRight="right"
          apiResponseKey={apiCalls.scheduleAppointments.toLowerCase()}
          apiUrl={apiCalls.scheduleAppointments}
          selectedId={params.id}
          displayActionsInTable={"actions"}
        />
      }
      {isOpenFormModal ? (
        <FormModal
          // onRef={(ref) => (this.formModalRef = ref)}
          formType="edit"
          openFormModal={isOpenFormModal}
          formFields={formFields}
        />
      ) : null}
      {openNewUserModal ? (
        <NewUserModal
          openNewUserModal={openNewUserModal}
          cancelReset={cancelReset}
          item={item}
          newFormFields={newFormFields}
          recordId={item._id}
          entityType="scheduleAppointments"
          apiUrl={apiCalls.scheduleAppointments}
        />
      ) : null}
    </span>
  );
};

export default ScheduleAppointments;
